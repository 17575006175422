<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row
          class="hp-profile-mobile-menu-btn bg-black-0 hp-bg-color-dark-100 rounded py-12 px-8 px-sm-12 mb-16 mx-0"
      >
        <div class="d-inline-block">
          <b-button
              variant="none"
              class="border-0 btn-icon-only"
              v-b-toggle.sidebar-profile
          >
            <i
                class="ri-menu-fill hp-text-color-black-80 hp-text-color-dark-30 lh-1"
                style="font-size: 24px"
            ></i>
          </b-button>
        </div>
      </b-row>

      <b-row
          class="bg-black-0 hp-bg-color-dark-100 rounded pr-16 pr-sm-32 mx-0"
      >
        <sidebar/>

        <b-col :key="key" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
          <!-- Komponen Vue yang menampilkan Informasi Pengguna      -->
          <!-- Komponen Vue yang menampilkan form edit  -->
          <EditUser
              :urlmatch="'manage-user-edit'"
              v-if="currentRouteName == 'manage-user-edit'"
          />
        </b-col>

      </b-row>

      <b-sidebar
          id="sidebar-profile"
          no-header
          shadow
          bg-variant="white"
          body-class="pt-16 px-0"
          class="hp-profile-mobile-menu"
          backdrop
      >
      </b-sidebar>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Sidebar from "../components/sidebar.vue";
import EditUser from "../EditUser/editUser";

export default {
  data() {
    return {
      routeName: "",
      key: 0
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.key++;
    next()
  },
  components: {
    BRow,
    BCol,
    BBadge,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    Sidebar,
    EditUser
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    $route(to) {
      if (to.name == "manage-user-edit") {
        this.$router.replace({name: 'manage-user-edit'})
      }
    }
  }
};
</script>
